<template>
  <div class="container">
    <div class="container__control">
      <ClosePopUpButton
        class="black"
      />
    </div>

    <div class="container__content">
      <h3>
        {{ $t('components.ui.pages.pop-up.password-changing.password-changing') }}
      </h3>

      <div
        v-if="!stepTo"
        class="container__content__tab"
      >
        <InputPassword
          style="margin: 20px 0 0;"
          :placeholder="`${$t('components.ui.pages.pop-up.password-changing.current')}*`"
          :errors="errors.step1.password"
          v-model="step1.password"
        />

        <div class="container__content__tab__link">
          <a
            @click="$auth.removeUser({ redirectName: 'ForgotPassword' })"
          >
            {{ $t('components.ui.pages.pop-up.password-changing.forgot') }}
          </a>
        </div>

        <BlueButton
          :text="$t('components.ui.pages.pop-up.password-changing.continue')"
          :loading="loading"
          :disabled="loading"
          @click="submit1()"
        />
      </div>

      <div
        v-else
        class="container__content__tab"
      >
        <InputPassword
          style="margin: 20px 0 0;"
          :placeholder="`${$t('components.ui.pages.pop-up.password-changing.new-password')}*`"
          :progress="_passwordProgression"
          :is-progress-error="_isErrorPasswordProgress"
          :errors="errors.step2.new_password"
          v-model="step2.new_password"
        />

        <InputPassword
          style="margin: 15px 0 20px;"
          :placeholder="`${$t('components.ui.pages.pop-up.password-changing.repeat-new-password')}*`"
          :errors="errors.step2.repeat_new_password"
          v-model="step2.repeat_new_password"
        />

        <BlueButton
          :text="`${$t('components.ui.pages.pop-up.password-changing.change-password')}*`"
          :loading="loading"
          :disabled="loading"
          @click="submit2()"
        />

        <div class="container__content__tab__link mb">
          <a @click="$pop.down()">
            {{ $t('components.ui.pages.pop-up.password-changing.cancel-changing') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'

import ClosePopUpButton from '@/ui/buttons/ClosePopUpButton.vue'
import InputPassword from '@/ui/inputs/Password.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    ClosePopUpButton,
    InputPassword,
    BlueButton
  },
  data () {
    return {
      v: useVuelidate(),
      stepTo: false,
      loading: false,

      step1: {
        password: ''
      },
      step2: {
        new_password: '',
        repeat_new_password: ''
      },

      errors: {
        step1: {
          password: []
        },
        step2: {
          new_password: [],
          repeat_new_password: []
        }
      }
    }
  },
  validations () {
    return {
      step1: {
        password: { required, minLength: minLength(6) }
      },
      step2: {
        new_password: { required, minLength: minLength(6) },
        repeat_new_password: { required }
      }
    }
  },
  computed: {
    _user () {
      return this.$store.state.user.data
    },
    _passwordProgression () {
      const pass = this.step2?.new_password ? this.step2?.new_password : ''
      const value = []

      const checkName = pass?.toLowerCase()
        .indexOf(this._user?.name?.replace(/\s+/g, '').toLowerCase()) !== -1

      const splitEmail = this._user?.email?.split('@')
      const checkEmail = pass
        .toLowerCase()
        .indexOf(typeof splitEmail === 'object' && splitEmail.length > 0
          ? splitEmail[0].toLowerCase() : this._user?.email?.toLowerCase()) !== -1

      if (checkName || checkEmail) {
        value.push({
          icon: 'error',
          text: 'errors.password-progress-no-copy'
        })
      } else {
        value.push({
          icon: 'success',
          text: 'errors.password-progress-no-copy'
        })
      }

      if (!(/[a-zA-Z]/.test(pass))) {
        value.push({
          icon: 'error',
          text: 'errors.password-progress-letter'
        })
      } else {
        value.push({
          icon: 'success',
          text: 'errors.password-progress-letter'
        })
      }

      if (!(/[A-Z]/.test(pass))) {
        value.push({
          icon: 'error',
          text: 'errors.password-progress-letter-upper'
        })
      } else {
        value.push({
          icon: 'success',
          text: 'errors.password-progress-letter-upper'
        })
      }

      if (!(/[0-9]/.test(pass))) {
        value.push({
          icon: 'error',
          text: 'errors.password-progress-number'
        })
      } else {
        value.push({
          icon: 'success',
          text: 'errors.password-progress-number'
        })
      }

      if (pass.length < 6) {
        value.push({
          icon: 'error',
          text: 'errors.password-progress-min'
        })
      } else {
        value.push({
          icon: 'success',
          text: 'errors.password-progress-min'
        })
      }

      return value
    },
    _isErrorPasswordProgress () {
      var count = 0

      for (const i in this._passwordProgression) {
        if (this._passwordProgression[i].icon === 'error') {
          count += 1
        }
      }

      return count > 0
    }
  },
  methods: {
    async submit1 () {
      this.loading = true
      this.v.step1.$touch()
      this._setErrors(1)

      if (this._countError(1) === 0) {
        const formData = new FormData()
        formData.append('password', this.step1.password)

        try {
          const resp = await this.$axios.post(
            '/private/profile/passwordCheck', formData)

          if (resp.status === 200) {
            this.stepTo = true
          } else {
            this.errors.step1.password.push('errors.password-not-valid')
          }
        } catch (error) {
          this.errors.step1.password.push('errors.password-not-valid')
          throw new Error(error)
        } finally {
          this.loading = false
        }
      }

      this.loading = false
    },
    async submit2 () {
      this.loading = true
      this.v.step2.$touch()
      this._setErrors(2)

      if (this._countError(2) === 0) {
        const formData = new FormData()
        formData.append('password', this.step2.new_password)
        formData.append('password_confirm', this.step2.repeat_new_password)

        try {
          const resp = await this.$axios.post(
            '/private/profile/passwordUpdate', formData)

          if (resp.status === 200) {
            this.$pop.up('successfully')
          }
        } catch (error) {
          throw new Error(error)
        } finally {
          this.loading = false
        }
      }

      this.loading = false
    },
    _setErrors (step) {
      const errorsForm1 = this.v.step1
      const errorsForm2 = this.v.step2
      const step2 = this.step2

      this.errors = {
        step1: {
          password: []
        },
        step2: {
          new_password: [],
          repeat_new_password: []
        }
      }

      if (step === 1) {
        if (errorsForm1.password.required.$invalid) {
          this.errors.step1.password.push('errors.required')
        }
        if (errorsForm1.password.minLength.$invalid) {
          this.errors.step1.password.push('errors.password-min')
        }
      } else if (step === 2) {
        // Password
        if (this._isErrorPasswordProgress) {
          this.form.errors.password.push('errors.required')
        }

        if (errorsForm2.repeat_new_password.required.$invalid) {
          this.errors.step2.repeat_new_password.push('errors.required')
        }
        if (step2.repeat_new_password !== step2.new_password) {
          this.errors.step2.repeat_new_password.push('errors.password')
        }
      }
    },
    _countError (step) {
      var count = 0

      if (step === 1) {
        const errors = this.errors.step1
        count += errors.password.length
        return count
      } else if (step === 2) {
        const errors = this.errors.step2
        count += errors.new_password.length + errors.repeat_new_password.length
        return count
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  max-width: 430px;
  padding: 10px;
  background: white;
  border-radius: 15px;
  overflow: hidden;

  .container__control {
    display: flex;
    justify-content: flex-end;
  }

  .container__content {
    padding: 0 10px 20px 10px;

    h3 {
      line-height: 100%;
      color: rgb(var(--color-dark));
      font-family: SemiBold;
    }

    .container__content__tab {
      .container__content__tab__link {
        margin: 15px 0;
        text-align: center;

        a {
          font-size: 14px;
          line-height: 100%;
          color: rgb(var(--primary-color));
          font-family: SemiBold;
          text-transform: uppercase;
          text-decoration: none;
          cursor: pointer;
        }

        &.mb {
          margin: 20px 0 0;
        }
      }
    }
  }
}
</style>
