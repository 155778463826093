<template>
  <div class="ci__ui">
    <div
      class="input-container flex"
      :class="{
        error: errors.length > 0 || (progress && isProgressError && modelValue?.length > 0)
      }"
      @click="$refs.input.focus()"
    >
      <input
        :type="view ? 'text' : 'password'"
        ref="input"
        :value="modelValue"
        max="30"
        maxlength="30"
        :name="name"
        :id="name"
        @focus="focus = true"
        @blur="focus = false"
        @input="$emit('update:modelValue', $event.target.value)"
      >

      <div
        class="input-container__view"
        @click="view = !view"
      >
        <svg
          v-if="view"
          width="24"
          height="24"
          style="min-width: 24px; min-height: 24px;"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 18.75C5.9055 18.75 1.11075 12.7193 0.909747 12.4628C0.806227 12.3307 0.749969 12.1678 0.749969 12C0.749969 11.8322 0.806227 11.6693 0.909747 11.5373C1.11075 11.2808 5.9055 5.25 12 5.25C18.0945 5.25 22.8892 11.2808 23.0902 11.5373C23.1938 11.6693 23.25 11.8322 23.25 12C23.25 12.1678 23.1938 12.3307 23.0902 12.4628C22.8892 12.7193 18.0945 18.75 12 18.75ZM2.48475 12C3.699 13.3605 7.55625 17.25 12 17.25C16.4437 17.25 20.301 13.3605 21.5152 12C20.301 10.6395 16.4437 6.75 12 6.75C7.55625 6.75 3.699 10.6395 2.48475 12Z" fill="#787C8D"/>
          <path d="M12 15.75C11.2583 15.75 10.5333 15.5301 9.91661 15.118C9.29993 14.706 8.81928 14.1203 8.53545 13.4351C8.25162 12.7498 8.17736 11.9958 8.32206 11.2684C8.46675 10.541 8.8239 9.8728 9.34835 9.34835C9.8728 8.8239 10.541 8.46675 11.2684 8.32206C11.9958 8.17736 12.7498 8.25162 13.4351 8.53545C14.1203 8.81928 14.706 9.29993 15.118 9.91661C15.5301 10.5333 15.75 11.2583 15.75 12C15.7488 12.9942 15.3533 13.9473 14.6503 14.6503C13.9473 15.3533 12.9942 15.7488 12 15.75ZM12 9.75C11.555 9.75 11.12 9.88196 10.75 10.1292C10.38 10.3764 10.0916 10.7278 9.92127 11.139C9.75098 11.5501 9.70642 12.0025 9.79323 12.439C9.88005 12.8754 10.0943 13.2763 10.409 13.591C10.7237 13.9057 11.1246 14.12 11.561 14.2068C11.9975 14.2936 12.4499 14.249 12.861 14.0787C13.2722 13.9084 13.6236 13.62 13.8708 13.25C14.118 12.88 14.25 12.445 14.25 12C14.25 11.4033 14.0129 10.831 13.591 10.409C13.169 9.98705 12.5967 9.75 12 9.75Z" fill="#787C8D"/>
        </svg>

        <svg
          v-else
          width="24"
          height="24"
          style="min-width: 24px; min-height: 24px;"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 18.75C11.4231 18.7488 10.8474 18.6951 10.2802 18.5895C10.1043 18.5613 9.94412 18.4714 9.8284 18.3359C9.71269 18.2004 9.649 18.0282 9.64874 17.85C9.65062 17.7401 9.67623 17.6319 9.72384 17.5329C9.77145 17.4338 9.83993 17.3462 9.92456 17.2761C10.0092 17.206 10.108 17.1551 10.2142 17.1267C10.3204 17.0984 10.4314 17.0934 10.5397 17.112C11.0212 17.2026 11.51 17.2488 12 17.25C16.443 17.25 20.3017 13.3597 21.5152 12C20.7066 11.0948 19.818 10.2645 18.8602 9.51898C18.7823 9.45865 18.7171 9.38357 18.6682 9.29803C18.6193 9.21248 18.5877 9.11814 18.5753 9.02039C18.5502 8.82299 18.6045 8.62369 18.7264 8.46635C18.8482 8.30901 19.0275 8.20652 19.225 8.18141C19.3227 8.16898 19.4219 8.17592 19.517 8.20184C19.6121 8.22776 19.7011 8.27215 19.779 8.33248C21.0001 9.27327 22.1111 10.3488 23.091 11.5387C23.1943 11.6707 23.2505 11.8335 23.2505 12.0011C23.2505 12.1687 23.1943 12.3315 23.091 12.4635C22.8892 12.7192 18.093 18.75 12 18.75Z" fill="#787C8D"/>
          <path d="M20.0302 3.96977C19.8896 3.82917 19.6989 3.75018 19.5 3.75018C19.3011 3.75018 19.1104 3.82917 18.9697 3.96977L16.5847 6.35477C15.6818 5.91964 14.7264 5.60302 13.7422 5.41277C13.1678 5.30476 12.5845 5.25027 12 5.25002C5.90699 5.25002 1.11074 11.2808 0.909743 11.5373C0.806408 11.6692 0.750259 11.832 0.750259 11.9996C0.750259 12.1673 0.806408 12.33 0.909743 12.462C1.88868 13.6509 2.99871 14.7255 4.21874 15.6653C4.79587 16.1176 5.40054 16.5337 6.02924 16.911L3.96974 18.9698C3.89811 19.039 3.84097 19.1217 3.80167 19.2132C3.76236 19.3047 3.74167 19.4031 3.74081 19.5027C3.73994 19.6023 3.75892 19.7011 3.79663 19.7932C3.83434 19.8854 3.89003 19.9691 3.96045 20.0396C4.03087 20.11 4.11461 20.1657 4.20678 20.2034C4.29895 20.2411 4.39771 20.2601 4.49729 20.2592C4.59688 20.2583 4.69529 20.2377 4.7868 20.1983C4.8783 20.159 4.96106 20.1019 5.03024 20.0303L20.0302 5.03027C20.1708 4.88963 20.2498 4.6989 20.2498 4.50002C20.2498 4.30115 20.1708 4.11042 20.0302 3.96977ZM5.13974 14.4818C4.18192 13.736 3.29332 12.9054 2.48474 12C3.69824 10.641 7.55699 6.75002 12 6.75002C12.4935 6.75042 12.9858 6.79661 13.4707 6.88802C14.1497 7.01719 14.8129 7.21833 15.4492 7.48802L14.0677 8.86952C13.3467 8.39307 12.4832 8.18031 11.6233 8.26724C10.7634 8.35417 9.95992 8.73545 9.3488 9.34658C8.73767 9.9577 8.35639 10.7612 8.26946 11.621C8.18253 12.4809 8.39529 13.3444 8.87174 14.0655L7.12499 15.8153C6.43039 15.4218 5.76672 14.976 5.13974 14.4818ZM9.97499 12.963C9.82883 12.6629 9.75194 12.3338 9.74999 12C9.75014 11.405 9.98518 10.8341 10.404 10.4115C10.7379 10.094 11.1591 9.88361 11.6136 9.80743C12.068 9.73125 12.5348 9.79275 12.954 9.98402L9.97499 12.963Z" fill="#787C8D"/>
        </svg>
      </div>

      <p
        class="input-container__placeholder"
        :class="{
          active: modelValue && modelValue.length > 0
        }"
      >
        {{ placeholder }}
      </p>

      <div
        v-if="errors.length > 0"
        class="error-icon"
      >
        <img src="@/static/svg/error-input-icon.svg">
      </div>
    </div>

    <div
      v-if="progress && modelValue?.length > 0"
      class="progress-password-list"
      :class="{
        simple: !isProgressError && !focus
      }"
    >
      <div
        v-for="(item, index) in progress"
        :key="index"
        class="progress-password-list__item"
      >
        <div class="progress-password-list__item__icon">
          <svg
            v-if="item.icon === 'error'"
            width="10" height="10"
            viewBox="0 0 10 10" fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5 6.06464L1.27376 9.79087C1.13435 9.93029 0.956907 10 0.741445 10C0.525982 10 0.348542 9.93029 0.209125 9.79087C0.0697082 9.65146 0 9.47402 0 9.25856C0 9.04309 0.0697082 8.86565 0.209125 8.72624L3.93536 5L0.209125 1.27376C0.0697082 1.13435 0 0.956907 0 0.741445C0 0.525982 0.0697082 0.348542 0.209125 0.209125C0.348542 0.0697082 0.525982 0 0.741445 0C0.956907 0 1.13435 0.0697082 1.27376 0.209125L5 3.93536L8.72624 0.209125C8.86565 0.0697082 9.04309 0 9.25856 0C9.47402 0 9.65146 0.0697082 9.79087 0.209125C9.93029 0.348542 10 0.525982 10 0.741445C10 0.956907 9.93029 1.13435 9.79087 1.27376L6.06464 5L9.79087 8.72624C9.93029 8.86565 10 9.04309 10 9.25856C10 9.47402 9.93029 9.65146 9.79087 9.79087C9.65146 9.93029 9.47402 10 9.25856 10C9.04309 10 8.86565 9.93029 8.72624 9.79087L5 6.06464Z" fill="#DA2828"/>
          </svg>

          <svg
            v-if="item.icon === 'success'"
            width="12" height="9"
            viewBox="0 0 12 9" fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M3.9998 6.5998L9.8998 0.699804C10.0831 0.516471 10.3165 0.424805 10.5998 0.424805C10.8831 0.424805 11.1165 0.516471 11.2998 0.699804C11.4831 0.883138 11.5748 1.11647 11.5748 1.3998C11.5748 1.68314 11.4831 1.91647 11.2998 2.0998L4.6998 8.6998C4.4998 8.8998 4.26647 8.9998 3.9998 8.9998C3.73314 8.9998 3.4998 8.8998 3.2998 8.6998L0.699804 6.0998C0.516471 5.91647 0.424805 5.68314 0.424805 5.3998C0.424805 5.11647 0.516471 4.88314 0.699804 4.6998C0.883138 4.51647 1.11647 4.4248 1.3998 4.4248C1.68314 4.4248 1.91647 4.51647 2.0998 4.6998L3.9998 6.5998Z" fill="#D3FF53"/>
          </svg>
        </div>

        <div class="progress-password-list__item__text">
          {{ $t(item.text) }}
        </div>
      </div>

      <p
        v-if="!isProgressError && !focus"
        class="progress-password-list__success"
      >
        {{ $t('info.password-progress-success') }}
      </p>
    </div>

    <template
      v-if="!progress"
    >
      <p
        v-for="(error, index) in errors"
        :key="index"
        class="ci__ui__error-text"
      >
        {{ $t(error) }}
      </p>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      required: true
    },
    progress: {
      type: [Boolean, Array],
      default: false
    },
    isProgressError: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      view: false,
      focus: false
    }
  },
  mounted () {
    if (this.autoFocus) {
      this.$refs.input.focus()
    }
  },
  watch: {
    modelValue () {
      const value = this.modelValue.replace(/\s+/g, '').replace(/[^a-zA-Z0-9!$#%]/g, '').trim()
      this.$emit('update:modelValue', value)
    },
    autoFocus () {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-password-list {
  padding: 5px 0 0;

  &.simple {
    display: flex;
    align-items: center;

    .progress-password-list__item {
      width: auto;

      .progress-password-list__item__text {
        display: none;
      }
    }
  }

  .progress-password-list__item {
    height: 24px;
    width: 100%;
    display: flex;
    align-items: center;

    .progress-password-list__item__icon {
      margin: 0 8px 0 6px;
    }

    .progress-password-list__item__text {
      font-size: 12px;
      line-height: 100%;
      color: #202539;
      font-family: Medium;
    }
  }

  .progress-password-list__success {
    font-size: 12px;
    line-height: 100%;
    color: #202539;
    font-family: Medium;
  }
}
</style>
